import type { CSSProperties } from 'react';
import type { SwiperAndGridBreakpointsProps } from '@mwl/core-lib';

export const getSwiperInlineStyles = (breakpoint: SwiperAndGridBreakpointsProps | null): CSSProperties => {
  if (!breakpoint) {
    return {};
  }

  const properties: Dictionary<string> = {};

  if (breakpoint.swiperCardMaxWidth) {
    properties[`--card-max-width`] = breakpoint.swiperCardMaxWidth;
  }

  if (breakpoint.swiperCardMinWidth) {
    properties[`--card-min-width`] = breakpoint.swiperCardMinWidth;
  }

  return properties;
};
