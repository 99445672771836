import type { FC } from 'react';
import { extendAnalyticsData } from '@mwl/core-lib';

import { SliderCards } from '@/components/SliderCards/SliderCards';
import { usePromo } from '@/hooks';

import { PromoCard } from '../PromoCard/PromoCard';

import type { PromotionsProps } from './Promotions.types';

import styles from './Promotions.module.scss';

const Promotions: FC<PromotionsProps> = ({ breakpoints, maxDisplayItemsCount, analytics, ...props }) => {
  const { items } = usePromo();
  const promotionsToShow = items.slice(0, maxDisplayItemsCount || items.length);

  if (!promotionsToShow.length) {
    return null;
  }

  return (
    <SliderCards
      totalGamesCount={items.length}
      breakpoints={breakpoints}
      sliderProps={{ classes: { slide: styles.slide, wrapper: styles.wrapper } }}
      analytics={analytics?.slider}
      {...props}
    >
      {promotionsToShow.map((promotion, index) => {
        return (
          <PromoCard
            as="card"
            {...promotion}
            key={promotion.id}
            analytics={extendAnalyticsData(analytics?.promotion, {
              href: promotion.url,
              count: promotionsToShow.length,
              position: index + 1,
            })}
          />
        );
      })}
    </SliderCards>
  );
};

export { Promotions };
