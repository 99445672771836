import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import { CardGridBase } from '@mwl/ui';

import { useMediaWidthDown } from '@/hooks';
import { commonObject } from '@/utils';

import { Button } from '../Button/Button';

import type { CardGridProps } from './CardGrid.types';

import styles from './CardGrid.module.scss';

const cardGridClasses = {
  root: styles.root,
  buttonContainer: styles.buttonContainer,
};

const BaseCardGrid: FC<CardGridProps> = (props) => {
  const isSmallScreen = useMediaWidthDown('screenXs');

  const { t } = useTranslation('casino');

  return (
    <CardGridBase
      {...props}
      {...commonObject.gamesGrid.container.nodeProps}
      classes={cardGridClasses}
      isSmallScreen={isSmallScreen}
      renderButton={({ isLoading, ...buttonProps }) => (
        <Button
          {...buttonProps}
          cut={false}
          variant="fulfilled"
          color="red"
          fullWidth
          className={styles.button}
          rounded
          disabled={isLoading}
        >
          {t('button.more_games', 'More games')}
        </Button>
      )}
    />
  );
};

export * from './CardGrid.types';
export const CardGrid = memo(BaseCardGrid);
