import type { FC } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import type { Swiper } from 'swiper/types';
import { extendAnalyticsData, getTestProps, useCurrentBreakpoint } from '@mwl/core-lib';

import ArrowLeftDouble from '@public/assets/common/icons/arrowLeftDouble.svg';
import ArrowLeftIcon from '@public/assets/common/icons/arrowLeftRound.svg';
import ArrowRightIcon from '@public/assets/common/icons/arrowRightRound.svg';

import { commonObject } from '@/utils';

import { Button } from '../Button/Button';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import type { SliderClasses } from '../Slider/Slider';
import { Slider } from '../Slider/Slider';

import { defaultSliderProps } from './SlideCards.data';
import { getAnalyticsMethods } from './SliderCard.analytics';
import type { SliderCardsProps } from './SliderCards.types';
import { getSwiperInlineStyles } from './SliderCards.utils';

import styles from './SliderCards.module.scss';

const defaultClasses: SliderClasses = {
  slide: styles.sliderSlide,
  wrapper: styles.sliderWrapper,
};

const SliderCards: FC<SliderCardsProps> = ({
  children,
  title,
  icon,
  isShowItemsCount = true,
  moreButtonText,
  moreButtonHref,
  className,
  totalGamesCount,
  breakpoints,
  sliderProps = {},
  headerVariant = 'uppercase',
  classes,
  analytics,
  listings,
  ...props
}) => {
  const prevRef = useRef<HTMLDivElement | null>(null);
  const nextRef = useRef<HTMLDivElement | null>(null);

  const currentBreakpoint = useCurrentBreakpoint(breakpoints);

  const { onTouchEnd, onNavigationPrev, onNavigationNext } = getAnalyticsMethods(analytics, {
    listings,
    count: totalGamesCount,
  });

  const onSwiperInit = useCallback((swiper: Swiper) => {
    const { navigation } = swiper.params;

    if (typeof navigation === 'boolean' || navigation == null) {
      return;
    }

    navigation.prevEl = prevRef.current;
    navigation.nextEl = nextRef.current;
    navigation.disabledClass = styles.arrowDisabled;
    navigation.lockClass = styles.arrowHide;

    swiper.navigation.init();
  }, []);

  const onSwiperAfterInit = useCallback((swiper: Swiper) => {
    swiper.navigation.update();
  }, []);

  const sliderClasses = useMemo(() => ({ ...defaultClasses, ...(sliderProps.classes ?? {}) }), [sliderProps]);

  return (
    <div className={cn(styles.root, className)} {...getTestProps(props)}>
      <div className={cn(styles.header, classes?.header)}>
        {title && (
          <h2
            {...commonObject.gamesGridLayout.title.nodeProps}
            className={cn(styles.title, styles[`${headerVariant}HeaderVariant`], {
              [styles.titleWithoutButton]: !moreButtonHref && !moreButtonText,
            })}
          >
            {!!icon && <span className={styles.icon}>{icon}</span>}
            <span className={styles.text}>{title}</span> {isShowItemsCount && <sup>{totalGamesCount}</sup>}
          </h2>
        )}
        <div className={styles.arrows}>
          <div className={styles.arrow} ref={prevRef}>
            <ButtonIcon
              className={styles.arrowIcon}
              as="button"
              variant="text"
              size="auto"
              aria-label="slider cards navigate prev"
            >
              <ArrowLeftIcon width={24} height={24} />
            </ButtonIcon>
          </div>

          <div className={styles.arrow} ref={nextRef}>
            <ButtonIcon
              className={styles.arrowIcon}
              as="button"
              variant="text"
              size="auto"
              aria-label="slider cards navigate next"
            >
              <ArrowRightIcon width={24} height={24} />
            </ButtonIcon>
          </div>

          {moreButtonHref && moreButtonText && (
            <Button
              {...commonObject.gamesGridLayout.allButton.nodeProps}
              as="link"
              href={moreButtonHref}
              color="white"
              variant="fulfilled"
              cut={false}
              className={styles.moreButton}
              fontWeight="bold"
              analytics={extendAnalyticsData(analytics?.moreButton, {
                listings,
                text: moreButtonText,
                href: moreButtonHref,
                count: totalGamesCount,
              })}
            >
              {moreButtonText}
              <ArrowLeftDouble width={14} height={12} />
            </Button>
          )}
        </div>
      </div>

      <Slider
        {...defaultSliderProps}
        breakpoints={breakpoints}
        className={styles.slider}
        onInit={onSwiperInit}
        onAfterInit={onSwiperAfterInit}
        style={getSwiperInlineStyles(currentBreakpoint)}
        {...sliderProps}
        watchOverflow
        classes={sliderClasses}
        onTouchEnd={onTouchEnd}
        onNavigationNext={onNavigationNext}
        onNavigationPrev={onNavigationPrev}
      >
        {children}
      </Slider>
    </div>
  );
};

export { SliderCards };
