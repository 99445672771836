import type { FC } from 'react';
import { extendAnalyticsData, getTestProps } from '@mwl/core-lib';

import { promoObject } from '@/utils';

import { Button } from '../Button/Button';
import { IconLabel } from '../IconLabel/IconLabel';
import { Image } from '../Image/Image';

import type { PromoCardProps } from './PromoCard.types';

import styles from './PromoCard.module.scss';

const PromoCard: FC<PromoCardProps> = ({
  as = 'card',
  className,
  url,
  button,
  content,
  image,
  style,
  title,
  labels,
  classes,
  colorVariant = 'dark',
  analytics,
  ...props
}) => {
  return (
    <div
      className={cn(styles.root, className, styles[`${as}Variant`], styles[`${colorVariant}Color`])}
      style={style}
      {...getTestProps(props)}
    >
      <div className={styles.redFilter} />

      {labels && labels.length !== 0 && (
        <div className={styles.labels}>
          {labels.map((label) => (
            <IconLabel variant={label} key={label} />
          ))}
        </div>
      )}

      <div className={cn(styles.background, classes?.image)}>
        <Image src={image} width={500} fill alt={title || 'Promo Card'} className={styles.backgroundImage} />
      </div>

      {title && (
        <div className={styles.content}>
          <div className={cn(styles.title, classes?.title)}>{title}</div>

          <div className={cn(styles.subtitle, classes?.subtitle)}>{content}</div>
        </div>
      )}

      {button && (
        <Button
          rounded
          className={cn(styles.button, classes?.button)}
          as="link"
          href={url || ''}
          variant="fulfilled"
          color="red"
          fontWeight="bold"
          fontSize="lg"
          analytics={extendAnalyticsData(analytics, { text: button, href: url })}
          {...promoObject.promotions.promotionButton.nodeProps}
        >
          <span className={styles.buttonText}>{button}</span>
        </Button>
      )}
    </div>
  );
};

export * from './PromoCard.types';
export { PromoCard };
