import type { FC } from 'react';
import { useLoadBlockGames } from '@mwl/core-lib';

import { GameCard } from '@/components/GameCard/GameCard';
import { SliderCards } from '@/components/SliderCards/SliderCards';
import { getGameAnalytics } from '@/utils';

import type { SliderCardsLoadableProps } from './SliderCardsLoadable.types';

const SliderCardsLoadable: FC<SliderCardsLoadableProps> = ({
  maxDisplayItemsCount,
  classes,
  loadMethod,
  breakpoints,
  onLoadFinished,
  analytics,
  ...props
}) => {
  const { items: allGames, totalElements } = useLoadBlockGames({ loadMethod, onLoadFinished });

  const gamesToShow = allGames.slice(0, maxDisplayItemsCount || allGames.length);

  if (!gamesToShow.length) {
    return null;
  }

  return (
    <SliderCards totalGamesCount={totalElements} breakpoints={breakpoints} analytics={analytics?.slider} {...props}>
      {gamesToShow.map((game, index) => (
        <GameCard
          className={classes?.card}
          key={game.id}
          {...game}
          analytics={getGameAnalytics({
            analytics: analytics?.game,
            listings: props.listings,
            count: gamesToShow.length,
            game,
            index,
          })}
        />
      ))}
    </SliderCards>
  );
};

export { SliderCardsLoadable };
