import type { Swiper } from 'swiper/types';
import type { AnalyticsData } from '@mwl/core-lib';
import { extendAnalyticsData, sendAnalyticsData } from '@mwl/core-lib';

import type { SliderCardsAnalytics } from '@/types';

export const getAnalyticsMethods = (analytics?: SliderCardsAnalytics, data: AnalyticsData = {}) => {
  const sliderNextAnalytics = extendAnalyticsData(analytics?.flippingRight, { ...data, position: 'right' });
  const sliderPrevAnalytics = extendAnalyticsData(analytics?.flippingLeft, { ...data, position: 'left' });

  const onNavigationNext = (swiper: Swiper) => {
    if (sliderNextAnalytics?.click) {
      sendAnalyticsData(sliderNextAnalytics.click?.eventName, {
        ...(sliderNextAnalytics.click?.data || {}),
        cls: swiper.navigation.nextEl.className,
      });
    }
  };

  const onNavigationPrev = (swiper: Swiper) => {
    if (sliderPrevAnalytics?.click) {
      sendAnalyticsData(sliderPrevAnalytics.click?.eventName, {
        ...(sliderPrevAnalytics.click?.data || {}),
        cls: swiper.navigation.prevEl.className,
      });
    }
  };

  const onTouchEnd = (swiper: Swiper) => {
    if (swiper.swipeDirection === 'prev' && sliderPrevAnalytics?.swipe) {
      sendAnalyticsData(sliderPrevAnalytics.swipe?.eventName, sliderPrevAnalytics.swipe?.data);
    } else if (swiper.swipeDirection === 'next' && sliderNextAnalytics?.swipe) {
      sendAnalyticsData(sliderNextAnalytics.swipe?.eventName, sliderNextAnalytics.swipe?.data);
    }
  };

  return { onNavigationNext, onNavigationPrev, onTouchEnd };
};
